export default [
	{
		to: '#about',
		name: 'About',
	},
	{
		to: '#work',
		name: 'Work',
	},
	{
		to: '#projects',
		name: 'Projects',
	},
	{
		to: '#contact',
		name: 'Contact',
	},
]
